import './header.css';
import LOGO from '../img/logo.png';
import twitter from '../img/twitter.png';
import React, { useState, useEffect, useMemo } from 'react';

import WalletComponent from './WalletComp.js';

import {
    ConnectionProvider,
    WalletProvider
} from '@solana/wallet-adapter-react';
import {
    WalletAdapterNetwork
} from '@solana/wallet-adapter-base';
import {
    WalletModalProvider
} from '@solana/wallet-adapter-react-ui';

import { PhantomWalletAdapter, PhantomWalletName } from '@solana/wallet-adapter-phantom';
import '@solana/wallet-adapter-react-ui/styles.css';

import { clusterApiUrl } from "@solana/web3.js"

const Header = () => {
    const network = WalletAdapterNetwork.Mainnet;

    const endpoint = useMemo(() => clusterApiUrl(network), [network])

    const wallets = useMemo(
        () => [
            {
                name: PhantomWalletName,
                wallet: PhantomWalletAdapter, //
            },
        ],
        [network]
    )

    


    useEffect(() => {
    }, []);
    return (
        <div>
            <div className='header head-foot'>
                <div className='head-foot-logo'>
                    <a><img src={LOGO} /></a>
                </div>
                <div className='head-foot-nav head-nav'>
                    <ConnectionProvider endpoint={endpoint}>
                        <WalletProvider wallets={wallets} autoConnect>
                            <WalletModalProvider>
                                <WalletComponent />
                            </WalletModalProvider>
                        </WalletProvider>
                    </ConnectionProvider>
                </div>
            </div>
            <div className='footer head-foot'>
                <div className='head-foot-nav foot-nav'>
                    <a> $BJINU </a>
                </div>
                <div className='head-foot-logo foot-logo'>
                    <a href='https://twitter.com/tickerbjinu' target='_blank'><img src={twitter} /></a>
                </div>
            </div>
        </div>
    );
}

export default Header;
