import './App.css';
import Header from './components/header.js';
import submit from './img/submit.png';
import chart from './img/chart.png';
import axios from 'axios';

import React from 'react';
import toast, { Toaster } from 'react-hot-toast';




const App = () => {

  const handleSubmit = async (e) => {
    e.preventDefault();

    let code = document.getElementById("input-code").value;
    let walletAddress = localStorage.getItem('connectedWallet');

    try {
      // const response = await axios.post('http://localhost:8000/api/submit', {
      const response = await axios.post('https://api.bjinu.xyz/api/submit', {

        code,
        walletAddress,
      });

      if (response.data.success === false) {
        console.log(response.data)
        toast.error(response.data.error)

      } else if (response.data.success === true) {
        toast.success("Congratulations! Code redeemed. Your wallet has been added.", {duration: 10000})

      } else {
        console.log("I don't know what happened.")
      }

    } catch (error) {

    }
  };




  return (
    <div className="App">
      <div><Toaster position='bottom-left' reverseOrder={true}/></div>
      <Header />
      <div className='App-wrapper'>
        <div className='centre'>
          <div className='bx'>
            <h1> Airdrop </h1>
            <text> Enter a valid code, receive an airdrop of $BJINU.</text>
          </div>
          <div className='bx'>
            <h1> BeetleJuiceInu </h1>
            <div className='input-div'>
              <input id="input-code" className='input-code' placeholder='Enter a code...' />
              <button onClick={handleSubmit} className='input-btn'><img className='input-img' src={submit} /></button>
            </div>
          </div>
          {/* <div className='bx'>
            <h1> Distribution </h1>
            <text> Tap/hover to view the chart. </text>
            <div className='chart-div'>
              <img className='chart' src={chart} />
            </div>
          </div> */}
        </div>
      </div>


    </div>
  );
}

export default App;
