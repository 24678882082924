// WalletComponent.js

import React, { useContext, useEffect } from 'react';
import { WalletContext, useWallet } from '@solana/wallet-adapter-react';
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';

const WalletComponent = ({ onWalletConnect }) => {
    const { wallet } = useContext(WalletContext);
    const { publicKey } = useWallet();
    try {
        if (wallet && publicKey) {
            try {
                localStorage.setItem('connectedWallet', publicKey.toString());
                onWalletConnect && onWalletConnect(publicKey.toString());
            } catch {
                console.log("Wallet not connected.")
            }
        }
    } catch {
        console.log("Error.")
    }
    return <WalletMultiButton />;
};

export default WalletComponent;
